<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <back url="/resource/index" />
    <div class="flex items-end justify-between m-t-sm m-b-sm">
      <!-- <div class="fl-menu flex items-center"></div> -->
      <div v-show="periodValue === 0">更新时间：{{getInspectTime}}<a-icon type="redo" class="m-l-xs pointer" title="更新" @click="updateDataHandle"/></div>
      <div class="flex items-center">
        <!-- 按路线 -->
        <div class="m-r-sm">
          <a-select
            v-model="periodValue"
            class="search-mx-wd-1"
            @change="selectHandle"
          >
            <a-select-option
              v-for="item in periodList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </div>

        <span class="span-line m-l-md m-r-md"></span>
        <a-button class="btn-export" @click="exportHandle">
          数据导出
        </a-button>
      </div>
    </div>
  <!--  <vue-scroll :ops="$root.scrollOpsY"  style="height:calc(100% - 100px);">
     --> 
     <div style="height:calc(100% - 100px)">
      <div class="fix  spin flex items-center justify-center text-white" v-show="!load">
        <a-spin tip="数据加载中..." size="large"/>
      </div>
      <!--  :loading="!load"  v-if="load"-->
      <div class="table-con">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :components="resibleTableHeader"
          :rowKey="(record, index) => record.regionCode"
          :pagination="false"
          :scroll="scroll"
          bordered
        >
          <span
            slot="customAccessRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customOnlineRate"
            slot-scope="text"
            :style="{ color: getColor(text) }"
          >
            {{ text }}
          </span>
          <span
            slot="customChain"
            slot-scope="text"
            :style="{ color: getArrowColor(text) }"
          >
            {{ text }}{{ getArrowText(text) }}
          </span>
          <span
            slot="customDirect"
            slot-scope="text"
            :style="{ color: getArrowColor(text) }"
          >
            {{ text }}{{ getArrowText(text) }}
          </span>
          <span
            slot="customOnlineChainRate"
            slot-scope="text"
            :style="{ color: getArrowColor(text) }"
          >
            {{ text }}{{ getArrowText(text) }}
          </span>
          <span
            slot="customOnlineYearRate"
            slot-scope="text"
            :style="{ color: getArrowColor(text) }"
          >
            {{ text }}{{ getArrowText(text) }}
          </span>
        </a-table>
      </div>
     </div>
   <!--  </vue-scroll> -->
    <!-- {{ selectList }} -->
  </div>
</template>

<script>
import Back from '@/components/Back';
import { handleExportFile } from '@/tools';
import ResizableTable from '@/components/mixins/resizeableTable';
const SUCCESS_CODE = 200;
let columnInfo = [
  {
    title: '排序',
    dataIndex:'orderIndex',
    customRender: (text, record, index) => index + 1,
    width: 140,
    type: 'all',
  },
  {
    title: '省份',
    dataIndex: 'province',
    key: 'province',
    type: 'all',
    width:100,
  },
  // 路线不显示这个
  {
    title: '应接入数',
    dataIndex: 'shouldNumber',
    key: 'shouldNumber',
    type: 'all',
    width:100
    /*  scopedSlots: {
      customRender: 'totalAccessCount',
    },
    type: 'filterItem', */
  },

  {
    title: '已接入数',
    dataIndex: 'alreadyNumber',
    key: 'alreadyNumber',
    type: 'all',
    width:100
    /* scopedSlots: {
      customRender: 'accessedCount',
    },
    type: 'filterItem', */
  },
  // 路线不显示这个
  {
    title: '接入率',
    dataIndex: 'accessRate',
    key: 'accessRate',
    // sorter: true,
    scopedSlots: {
      customRender: 'customAccessRate',
    },
    type: 'all',
    sorter: (a, b) =>
      Number(String(a.accessRate).slice(0, String(a.accessRate).length - 1)) -
      Number(String(b.accessRate).slice(0, String(b.accessRate).length - 1)),
    sortDirections: ['descend'],  
    width:100
    /*   type: 'filterItem', */
  },
  {
    title: '环比',
    dataIndex: 'chain',
    key: 'chain',
    // sorter: true,
    scopedSlots: {
      customRender: 'customChain',
    },
    /*   type: 'filterItem', */
    type: 'yearDay',
    type2: 'weekDay',
    width:100
  },
  {
    title: '同比',
    dataIndex: 'direct',
    key: 'direct',
    // sorter: true,
    scopedSlots: {
      customRender: 'customDirect',
    },
    /*   type: 'filterItem', */
    type: 'yearDay',
    width:100
  },
  {
    title: '在线数',
    dataIndex: 'online',
    key: 'online',
    type: 'all',
    type2: 'noOnline',
    width:100
  },
  {
    title: '在线率',
    dataIndex: 'onlineRate',
    key: 'onlineRate',
    // sorter: true,
    scopedSlots: {
      customRender: 'customOnlineRate',
    },
    type: 'all',
    sorter: (a, b) =>
      Number(String(a.onlineRate).slice(0, String(a.onlineRate).length - 1)) -
      Number(String(b.onlineRate).slice(0, String(b.onlineRate).length - 1)),
    sortDirections: ['descend'],
    width:100
  },
  {
    title: '环比',
    dataIndex: 'onlineChain',
    key: 'onlineChain',
    // sorter: true,
    scopedSlots: {
      customRender: 'customOnlineChainRate',
    },
    type: 'yearDay',
    type2: 'weekDay',
    width:100
    /*   type: 'filterItem', */
  },
  {
    title: '同比',
    dataIndex: 'onlineDirect',
    key: 'onlineDirect',
    // sorter: true,
    scopedSlots: {
      customRender: 'customOnlineYearRate',
    },
    type: 'yearDay',
    width:100
    /*   type: 'filterItem', */
  },
  {
    title: '检测时间',
    dataIndex: 'inspectTime',
    key: 'inspectTime',
    type: 'instant',
    width:120
  },
];
export default {
  name: 'NetCondition',
  mixins: [ResizableTable],
  data() {
    return {
      periodList: [
        { id: 0, text: '实时' },
        { id: 1, text: '昨日' },
        { id: 2, text: '近一周' },
        { id: 3, text: '上一周' },
      ],
      periodValue: 0,
      columns: [],
      dataList: [],
      load: true,
      scroll:{y:window.innerHeight - 300, x:'100%'}
    };
  },
  components: {
    Back,
  },
  computed: {
    getInspectTime() {
      return this.dataList.length > 0 ? this.dataList[0]['inspectTime']:'--' ;
    }
  },
  watch: {},
  created() {
    this.initColumn();
  },
  methods: {
    initColumn() {
      if (this.periodValue === 0) {
        this.columns = columnInfo.filter(
          item => item.type == 'all' || item.type == 'instant'
        )
        this.getNowStatistics();
      } else if (this.periodValue === 1) {
        this.columns = columnInfo.filter(
          item => item.type == 'all' || item.type == 'yearDay'
        )
        this.getCameraNetworkInfo();
      } else if (this.periodValue === 2 || this.periodValue === 3) {
        this.columns = columnInfo.filter(
          item =>
            (item.type == 'all' && item.type2 != 'noOnline') ||
            item.type2 == 'weekDay'
        )
        this.getCameraNetworkInfo();
      }
    },
    selectHandle(value) {
      this.periodValue = value;
      this.initColumn();
    },
    getColor(text) {
      let ntext = parseInt(text);
      return `${
        ntext < 60
          ? '#FD4217'
          : ntext >= 60 && ntext < 80
          ? '#F09C23'
          : '#45CD8F'
      }`;
    },
    getArrowText(text) {
      let ntext = text
        ? Number(String(text).slice(0, String(text)[String(text).length - 1]))
        : -1;
      return `${ntext >= 0 ? '↑' : '↓'}`;
    },
    getArrowColor(text) {
      let ntext = Number(text);
      return `${ntext >= 0 ? '#FD4217' : '#45CD8F'}`;
    },
    getCameraNetworkInfo() {
      let params = {
        type: this.periodValue,
        sort: 0,
      };
      this.dataList = [];
      this.load = false;
      this.$api.resource.getCameraNetWorkInfo(params).then(data => {
        if (data.code == SUCCESS_CODE) {
          this.dataList = data.data;
          this.load = true;
        }
      });
    },
    getNowStatistics() {
      this.dataList = [];
      this.load = false;
      this.$api.resource.getNowStatistics().then(res => {
        if (res.code == SUCCESS_CODE) {
          this.dataList = res.data;
          this.load = true;
        }
      });
    },
    //更新数据
    updateDataHandle() {
      this.load = false;
      this.$api.resource.getNowStatisticsAgain().then(res => {
        if(res.code == SUCCESS_CODE) {
          this.dataList = res.data;
          this.load = true;
        }
      })
    },
    exportHandle() {
      if (!this.hasPermission(109211101011)) {
        this.permissionWarning();
        return;
      }

      if (this.periodValue === 0) {
        this.$api.resource.exportNowStatistics().then(res => {
          handleExportFile(res, '摄像机联网状况统计详情.xls');
        });
      } else {
        let params = {
          type: this.periodValue,
          sort: 0,
        };
        this.$api.resource.exportCameraNetWork(params).then(res => {
          handleExportFile(res, '摄像机联网状况统计详情.xls');
        });
      }
    },
  },
};
</script>
<style scoped>
.spin {
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  /* transform:translate(-50%,-50%); */
  z-index: 999;
  background: rgba(0,0,0,0.2);
  /* border:1px solid rgb(163, 177, 202); */
  padding:30px;
}
::v-deep .ant-spin{
  color: #fff;
}
::v-deep .ant-spin-dot-item{
  background:#fff;
}
</style>
